import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, SimpleGrid } from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';
import DateInput from '../../components/DateInput';
import SelectInput from '../../components/SelectInput';

import getDateFromString from '../../helpers/getDateFromString';
import getDateString from '../../helpers/getDateString';

import { changeMethodOfPayment } from 'reducers/submissionFormReducer';

const PAYMENT_PLAN_OPTIONS = [
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Quarterly', label: 'Quarterly' },
  { value: 'Annually', label: 'Annually' },
];

const MethodOfPayment = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.submissionForm.data.method_of_payment,
  );

  const onMethodOfPaymentChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeMethodOfPayment({ name, value }));
  };

  const onDateChange = (date: Date, name: string) => {
    dispatch(changeMethodOfPayment({ name, value: getDateString(date) }));
  };

  return (
    <Card mb="20px" id="sectionMethodOfPayment">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Method of Payment
      </Text>

      <SimpleGrid columns={5} spacing="20px">
        <SelectInput
          label="Type of Payment Plan"
          name="type_of_payment_plan"
          value={data.type_of_payment_plan}
          options={PAYMENT_PLAN_OPTIONS}
          onChange={onMethodOfPaymentChange}
        />

        <FormInput
          label="Estimated Policy Premium ** "
          name="estimated_policy_premium"
          value={data.estimated_policy_premium}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          label="Tax"
          name="tax"
          value={data.tax}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          label="Interest"
          name="interest"
          value={data.interest}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          label="Total Estimated Cost"
          name="total_estimated_cost"
          value={data.total_estimated_cost}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          label="Amt. Paid with Application"
          name="amt_paid_with_application"
          value={data.amt_paid_with_application}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          label="Amount Still Due"
          name="amount_still_due"
          value={data.amount_still_due}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          label="No. of Remaining Instalments"
          name="no_of_remaining_instalments"
          value={data.no_of_remaining_instalments}
          onChange={onMethodOfPaymentChange}
          required
        />

        <FormInput
          label="Amount of Each Instalment"
          name="amount_of_each_instalment"
          value={data.amount_of_each_instalment}
          onChange={onMethodOfPaymentChange}
          required
        />

        <DateInput
          label="Instalment Due Date"
          date={getDateFromString(data.instalment_due_date)}
          onChange={(date: Date) => onDateChange(date, 'instalment_due_date')}
          required
        />
      </SimpleGrid>
    </Card>
  );
};

export default MethodOfPayment;
