import Card from 'components/card/Card';

import { Text } from '@chakra-ui/react';

import Message from '../../components/Message';

import { FILE_TYPE } from '../../constants';

const PersonalConsent = () => {
  return (
    <Card mb="20px">
      <Text fontWeight="bold" fontSize="2xl">
        Personal consent form
      </Text>

      <Message
        validationId="personal_consent_form"
        manual={{
          show: true,
          message: 'Upload the personal consent form.',
        }}
        file={FILE_TYPE.TYPE_PERSONAL_CONSENT_FORM}
        hideRemarks
        hideMessage
      />
    </Card>
  );
};

export default PersonalConsent;
