import type { APISubmissionStatuses, APIUserRoles } from 'types/api.types';

import {
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_APPROVED,
  SUBMISSION_STATUS_COMPLETED,
  ROLE_ADMIN,
} from 'variables/constants';

const isSubmissionDisabled = (
  submissionStatus: APISubmissionStatuses,
  userRole: APIUserRoles,
) => {
  if (userRole === ROLE_ADMIN) return false;

  if (
    [
      SUBMISSION_STATUS_FAILED,
      SUBMISSION_STATUS_APPROVED,
      SUBMISSION_STATUS_COMPLETED,
    ].includes(submissionStatus)
  ) {
    return true;
  }

  return false;
};

export default isSubmissionDisabled;
