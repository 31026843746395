import { useContext } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Text,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  SimpleGrid,
  Box,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';
import CheckBoxInput from '../../components/CheckBoxInput';

import {
  changePolicyInformation,
  changeLanguage,
} from 'reducers/submissionFormReducer';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

import { AuthContext } from 'contexts/AuthContext';

const PolicyInformation = () => {
  const { organization } = useContext(AuthContext);
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.submissionForm.data.policy_information,
  );

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const isDisabled = isSubmissionDisabled(submissionStatus, organization.role);

  const onPolicyInformationChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changePolicyInformation({ name, value: payload }));
  };

  return (
    <Card mb="20px" id="sectionPolicyInformation">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Policy Information
      </Text>

      <SimpleGrid columns={4} spacing="20px" mb="20px">
        <FormInput
          label="Policy No. Assigned"
          name="policy_no_assigned"
          value={data.policy_no_assigned}
          onChange={onPolicyInformationChange}
          required
        />
        <FormInput
          label="Replacing Policy No."
          name="replacing_policy_no"
          value={data.replacing_policy_no}
          onChange={onPolicyInformationChange}
        />
        <Box mt="40px" textAlign="center">
          <CheckBoxInput
            name="new_policy"
            isChecked={data.new_policy}
            onChange={onPolicyInformationChange}
          >
            New Policy
          </CheckBoxInput>
        </Box>
        <FormControl>
          <RadioGroup
            value={data.language_preferred}
            onChange={(value: any) => dispatch(changeLanguage(value))}
            isDisabled={isDisabled}
          >
            <FormLabel>Language Preferred</FormLabel>
            <Stack direction="row">
              <Radio value="en">English</Radio>
              <Radio value="fe">French</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={6} spacing="20px">
        <Box mt="40px">
          <CheckBoxInput
            name="company_bill"
            isChecked={data.company_bill}
            onChange={onPolicyInformationChange}
          >
            Company Bill
          </CheckBoxInput>
        </Box>
        <Box mt="40px">
          <CheckBoxInput
            name="broker_agent_bill"
            isChecked={data.broker_agent_bill}
            onChange={onPolicyInformationChange}
          >
            Broker/Agent Bill
          </CheckBoxInput>
        </Box>
        <FormInput
          label="Other (Specify)"
          name="other_bill"
          value={data.other_bill}
          onChange={onPolicyInformationChange}
        />
      </SimpleGrid>
    </Card>
  );
};

export default PolicyInformation;
