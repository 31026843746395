import { useContext } from 'react';

import { useAppSelector } from 'hooks';

import { Box } from '@chakra-ui/react';

import SelectField from 'components/fields/SelectField';

import Message from '../Message';

import { MessageProps } from '../Message/Message.types';

import { AuthContext } from 'contexts/AuthContext';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

const SelectInput = ({
  label,
  options,
  onChange,
  message,
  ...rest
}: {
  message?: MessageProps;
  onChange?: (event: any) => void;
  [x: string]: any;
}) => {
  const { organization } = useContext(AuthContext);

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const isDisabled = isSubmissionDisabled(submissionStatus, organization.role);

  return (
    <Box>
      <SelectField
        label={label}
        options={options}
        onChange={onChange}
        {...rest}
        disabled={isDisabled}
        backgroundColor={isDisabled ? 'gray.300' : 'white'}
      />
      {message && <Message {...message} />}
    </Box>
  );
};

export default SelectInput;
