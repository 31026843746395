const submissionFormMiddleware =
  (storeAPI: any) => (next: any) => (action: any) => {
    const result = next(action);

    if (
      [
        'submissionForm/changeIsDataSaved',
        'submissionForm/changeLiabilitySlip',
        'submissionForm/changeLiabilitySlipDays',
        'submissionForm/changeSubmissionStatus',
      ].includes(action.type)
    ) {
      return result;
    }

    if (action.type.startsWith('submissionForm/')) {
      storeAPI.dispatch({
        type: 'submissionForm/changeIsDataSaved',
        payload: false,
      });
    }

    return result;
  };

export default submissionFormMiddleware;
