import { useContext } from 'react';

import CheckboxField from 'components/fields/CheckboxField';

import { useAppSelector } from 'hooks';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

import { AuthContext } from 'contexts/AuthContext';

import Message from '../Message';

import { MessageProps } from '../Message/Message.types';

const CheckBoxInput = ({
  message,
  children,
  ...rest
}: {
  message?: MessageProps;
  onChange?: (event: any) => void;
  [x: string]: any;
}) => {
  const { organization } = useContext(AuthContext);

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const isDisabled = isSubmissionDisabled(submissionStatus, organization.role);

  return (
    <>
      <CheckboxField {...rest} disabled={isDisabled}>
        {children}
      </CheckboxField>
      {message && <Message {...message} />}
    </>
  );
};

export default CheckBoxInput;
