import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Storage from 'storage';

import type {
  APIApplicationForm,
  APILiabilitySlip,
  APISubmissionStatuses,
} from 'types/api.types';

import type { SubmissionFormState } from './submissionFormReducer.types';

const initialState: SubmissionFormState = {
  isDataSet: false,
  isDataSaved: false,
  uuid: null,
  data: null,
  remarks: {},
  files: {},
  liability_slip: null,
  missingMandatoryFields: [],
  missingRemarksFields: {},
  status: null,
};

const updateLocalStore = (state: SubmissionFormState) => {
  Storage.store(`app_form_${state.uuid}`, {
    data: state.data,
    remarks: state.remarks,
    files: state.files,
    liability_slip: state.liability_slip,
  });
};

export const submissionFormSlice = createSlice({
  name: 'submissionForm',
  initialState,
  reducers: {
    storeData: (
      state,
      action: PayloadAction<{
        uuid: string;
        data: APIApplicationForm;
        remarks?: any;
        files?: any;
        liability_slip?: APILiabilitySlip;
        status: APISubmissionStatuses;
      }>,
    ) => {
      state.uuid = action.payload.uuid;
      state.data = action.payload.data;

      if (action.payload?.remarks) {
        state.remarks = action.payload.remarks;
      }

      if (action.payload?.files) {
        state.files = action.payload.files;
      }

      if (action.payload?.liability_slip) {
        state.liability_slip = action.payload.liability_slip;
      }

      state.missingMandatoryFields = [];
      state.missingRemarksFields = {};
      state.isDataSet = true;
      state.status = action.payload.status;

      updateLocalStore(state);
    },

    resetData: (state) => {
      state.isDataSet = false;
      state.uuid = null;
      state.data = null;
      state.remarks = {};
      state.files = {};
    },

    changeIsDataSaved: (state, action: PayloadAction<boolean>) => {
      state.isDataSaved = action.payload;
    },

    changePolicyInformation: (
      state,
      action: PayloadAction<{ name: string; value: string | boolean }>,
    ) => {
      state.data.policy_information = {
        ...state.data.policy_information,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeLanguage: (state, action: PayloadAction<'en' | 'fe'>) => {
      state.data.policy_information = {
        ...state.data.policy_information,
        language_preferred: action.payload,
      };
      updateLocalStore(state);
    },

    changeCompanyAndBrokerInformation: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.company_and_broker_information = {
        ...state.data.company_and_broker_information,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeApplicant: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.applicant = {
        ...state.data.applicant,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeLessor: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.lessor = {
        ...state.data.lessor,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changePolicyPeriod: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.policy_period = {
        ...state.data.policy_period,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeRemarks: (
      state,
      action: PayloadAction<{ name: string; value: string; key: string }>,
    ) => {
      state.remarks = {
        ...state.remarks,
        [action.payload.name]: {
          ...state.remarks[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };

      // Remove key if value is empty
      if (!action.payload.value) {
        const remarksCopy = { ...state.remarks };
        delete remarksCopy[action.payload.name][action.payload.key];

        if (Object.keys(remarksCopy[action.payload.name]).length === 0) {
          delete remarksCopy[action.payload.name];
        }

        state.remarks = remarksCopy;
      }

      updateLocalStore(state);
    },

    changeFiles: (
      state,
      action: PayloadAction<{ name: string; value: string; key: string }>,
    ) => {
      state.files = {
        ...state.files,
        [action.payload.name]: {
          ...state.files[action.payload.name],
          [action.payload.key]: action.payload.value,
        },
      };

      updateLocalStore(state);
    },

    removeFiles: (
      state,
      action: PayloadAction<{ name: string; key: string }>,
    ) => {
      const filesCopy = { ...state.files };
      delete filesCopy[action.payload.name][action.payload.key];

      if (Object.keys(filesCopy[action.payload.name]).length === 0) {
        delete filesCopy[action.payload.name];
      }

      state.files = filesCopy;
      updateLocalStore(state);
    },

    changeVehicle: (
      state,
      action: PayloadAction<{
        index: number;
        name: string;
        value: string | boolean;
      }>,
    ) => {
      state.data.vehicles = state.data.vehicles.map(
        (vehicle: any, index: number) => {
          if (index === action.payload.index) {
            const extra = {} as any;

            // Fields for which an opposite value needs to be flipped
            const reverseKeys = {
              is_owend: 'is_leased',
              is_leased: 'is_owend',
              is_purchased_new: 'is_purchased_used',
              is_purchased_used: 'is_purchased_new',
            } as any;

            if (action.payload.name in reverseKeys) {
              extra[reverseKeys[action.payload.name]] = !action.payload.value;
            }

            return {
              ...vehicle,
              [action.payload.name]: action.payload.value,
              ...extra,
            };
          }

          return vehicle;
        },
      );

      updateLocalStore(state);
    },

    changeVehicleSummary: (
      state,
      action: PayloadAction<{ name: string; value: string | boolean }>,
    ) => {
      state.data.vehicles_summary = {
        ...state.data.vehicles_summary,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeUnlistedVehicles: (
      state,
      action: PayloadAction<{
        name: string;
        value: string | boolean;
        index: number;
      }>,
    ) => {
      state.data.unlisted_vehicles = state.data.unlisted_vehicles.map(
        (vehicle: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...vehicle,
              [action.payload.name]: action.payload.value,
            };
          }

          return vehicle;
        },
      );

      updateLocalStore(state);
    },

    addUnlistedVehicle: (
      state,
      action: PayloadAction<{
        name: string;
        vin: string;
        is_in_household: boolean;
      }>,
    ) => {
      state.data.unlisted_vehicles = [
        ...state.data.unlisted_vehicles,
        {
          name: action.payload.name,
          vin: action.payload.vin,
          is_in_household: action.payload.is_in_household,
          no_reason: '',
          carrier: '',
          policy: '',
          expiry_date: '',
          uninsured_reason: '',
          remarks: '',
        },
      ];
      updateLocalStore(state);
    },

    changeDriver: (
      state,
      action: PayloadAction<{
        index: number;
        name: string;
        value: string | boolean;
      }>,
    ) => {
      state.data.drivers = state.data.drivers.map(
        (driver: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...driver,
              [action.payload.name]: action.payload.value,
            };
          }

          return driver;
        },
      );
      updateLocalStore(state);
    },

    changeDriverVehiclePercentageUse: (
      state,
      action: PayloadAction<{
        driver_index: number;
        vehicle_id: number;
        value: string;
      }>,
    ) => {
      state.data.drivers = state.data.drivers.map(
        (driver: any, index: number) => {
          if (index === action.payload.driver_index) {
            return {
              ...driver,
              vehicle_percentage_use: {
                ...driver.vehicle_percentage_use,
                [`vehicle_${action.payload.vehicle_id}`]: action.payload.value,
              },
            };
          }

          return driver;
        },
      );

      updateLocalStore(state);
    },

    changeDriverSummary: (
      state,
      action: PayloadAction<{ name: string; value: string | boolean }>,
    ) => {
      state.data.drivers_summary = {
        ...state.data.drivers_summary,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeMostRecentPolicy: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.most_recent_policy = {
        ...state.data.most_recent_policy,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    addUnlistedSpouse: (
      state,
      action: PayloadAction<{
        is_in_household: boolean;
      }>,
    ) => {
      state.data.unlisted_spouses = [
        ...state.data.unlisted_spouses,
        {
          is_in_household: action.payload.is_in_household,
          name: '',
          no_reason: '',
          license_number: '',
          carrier: '',
          policy: '',
          expiry_date: '',
          unlicensed_reason: '',
          remarks: '',
        },
      ];

      updateLocalStore(state);
    },

    changeUnlistedSpouse: (
      state,
      action: PayloadAction<{
        name: string;
        value: string | boolean;
        index: number;
      }>,
    ) => {
      state.data.unlisted_spouses = state.data.unlisted_spouses.map(
        (spouse: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...spouse,
              [action.payload.name]: action.payload.value,
            };
          }

          return spouse;
        },
      );

      updateLocalStore(state);
    },

    changeAccidentsAndClaims: (
      state,
      action: PayloadAction<{
        name: string;
        index: number;
        value: string;
      }>,
    ) => {
      state.data.accidents_and_claims = state.data.accidents_and_claims.map(
        (claim: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...claim,
              [action.payload.name]: action.payload.value,
            };
          }

          return claim;
        },
      );

      updateLocalStore(state);
    },

    addAccidentsAndClaims: (state) => {
      state.data.accidents_and_claims = [
        ...state.data.accidents_and_claims,
        {
          auto_nr: '',
          date: '',
          details: '',
          driver_nr: '',
          is_ab: false,
          is_bi: false,
          is_coll_ap: false,
          is_comp_sp: false,
          is_dcpd: false,
          is_pd: false,
          is_ua: false,
          paid: '',
        },
      ];

      updateLocalStore(state);
    },

    removeAccidentsAndClaims: (state, action: PayloadAction<number>) => {
      state.data.accidents_and_claims = state.data.accidents_and_claims.filter(
        (_: any, index: number) => index !== action.payload,
      );

      updateLocalStore(state);
    },

    changeConvictions: (
      state,
      action: PayloadAction<{ name: string; index: number; value: string }>,
    ) => {
      state.data.convictions = state.data.convictions.map(
        (conviction: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...conviction,
              [action.payload.name]: action.payload.value,
            };
          }

          return conviction;
        },
      );

      updateLocalStore(state);
    },

    addConviction: (state) => {
      state.data.convictions = [
        ...state.data.convictions,
        {
          date: '',
          details: '',
          driver_nr: '',
        },
      ];

      updateLocalStore(state);
    },

    removeConviction: (state, action: PayloadAction<number>) => {
      state.data.convictions = state.data.convictions.filter(
        (_: any, index: number) => index !== action.payload,
      );

      updateLocalStore(state);
    },

    changeRatingInformationDrivingRecord: (
      state,
      action: PayloadAction<{ name: string; index: number; value: string }>,
    ) => {
      state.data.rating_information_driving_record =
        state.data.rating_information_driving_record.map(
          (record: any, index: number) => {
            if (index === action.payload.index) {
              return {
                ...record,
                [action.payload.name]: action.payload.value,
              };
            }

            return record;
          },
        );

      updateLocalStore(state);
    },

    addRatingInformationDrivingRecord: (state) => {
      state.data.rating_information_driving_record = [
        ...state.data.rating_information_driving_record,
        {
          ab: '',
          at_fault_claim_description: '',
          at_fault_claim_percentage: '',
          auto_class: '',
          auto_nr: '',
          bi: '',
          coll_ap: '',
          conviction_description: '',
          conviction_percentage: '',
          dcpd: '',
          driver_principal: '',
          driver_secondary: '',
          pd: '',
        },
      ];

      updateLocalStore(state);
    },

    removeRatingInformationDrivingRecord: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.data.rating_information_driving_record =
        state.data.rating_information_driving_record.filter(
          (_: any, index: number) => index !== action.payload,
        );

      updateLocalStore(state);
    },

    changeRatingInformationDiscounts: (
      state,
      action: PayloadAction<{ name: string; index: number; value: string }>,
    ) => {
      state.data.rating_information_discounts =
        state.data.rating_information_discounts.map(
          (discount: any, index: number) => {
            if (index === action.payload.index) {
              return {
                ...discount,
                [action.payload.name]: action.payload.value,
              };
            }

            return discount;
          },
        );

      updateLocalStore(state);
    },

    addRatingInformationDiscounts: (state) => {
      state.data.rating_information_discounts = [
        ...state.data.rating_information_discounts,
        {
          ab: '',
          auto_nr: '',
          coll_ap: '',
          comp_sp: '',
          dcpd: '',
          list_price_new: '',
          location: '',
          territory: '',
          vehicle_code: '',
          discounts: [
            {
              discount_description: '',
              discount_percentage: '',
            },
          ],
        },
      ];

      updateLocalStore(state);
    },

    removeRatingInformationDiscount: (state, action: PayloadAction<number>) => {
      state.data.rating_information_discounts =
        state.data.rating_information_discounts.filter(
          (_: any, index: number) => index !== action.payload,
        );

      updateLocalStore(state);
    },

    changeRatingInformationDiscountsDiscount: (
      state,
      action: PayloadAction<{
        name: string;
        index: number;
        discountIndex: number;
        value: string;
      }>,
    ) => {
      state.data.rating_information_discounts =
        state.data.rating_information_discounts.map(
          (discount: any, index: number) => {
            if (index === action.payload.index) {
              return {
                ...discount,
                discounts: discount.discounts.map(
                  (discount: any, discountIndex: number) => {
                    if (discountIndex === action.payload.discountIndex) {
                      return {
                        ...discount,
                        [action.payload.name]: action.payload.value,
                      };
                    }

                    return discount;
                  },
                ),
              };
            }

            return discount;
          },
        );

      updateLocalStore(state);
    },

    addRatingInformationDiscountsDiscount: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.data.rating_information_discounts =
        state.data.rating_information_discounts.map(
          (discount: any, index: number) => {
            if (index === action.payload) {
              return {
                ...discount,
                discounts: [
                  ...discount.discounts,
                  {
                    discount_description: '',
                    discount_percentage: '',
                  },
                ],
              };
            }

            return discount;
          },
        );

      updateLocalStore(state);
    },

    removeRatingInformationDiscountsDiscount: (
      state,
      action: PayloadAction<{
        index: number;
        discountIndex: number;
      }>,
    ) => {
      state.data.rating_information_discounts =
        state.data.rating_information_discounts.map(
          (discount: any, index: number) => {
            if (index === action.payload.index) {
              return {
                ...discount,
                discounts: discount.discounts.filter(
                  (_: any, discountIndex: number) =>
                    discountIndex !== action.payload.discountIndex,
                ),
              };
            }

            return discount;
          },
        );

      updateLocalStore(state);
    },

    addUnlistedDriver: (
      state,
      action: PayloadAction<{
        name: string;
        license_number: string;
        is_in_household: boolean;
      }>,
    ) => {
      state.data.unlisted_drivers = [
        ...state.data.unlisted_drivers,
        {
          name: action.payload.name,
          license_number: action.payload.license_number,
          is_in_household: action.payload.is_in_household,
          no_reason: '',
          carrier: '',
          policy: '',
          expiry_date: '',
          unlicensed_reason: '',
          remarks: '',
        },
      ];

      updateLocalStore(state);
    },

    changeUnlistedDriver: (
      state,
      action: PayloadAction<{
        name: string;
        value: string | boolean;
        index: number;
      }>,
    ) => {
      state.data.unlisted_drivers = state.data.unlisted_drivers.map(
        (driver: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...driver,
              [action.payload.name]: action.payload.value,
            };
          }

          return driver;
        },
      );

      updateLocalStore(state);
    },

    changeInsuranceCoverageLiability: (
      state,
      action: PayloadAction<{
        index: number;
        name: string;
        limit?: number;
        premium?: number;
      }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              liability: {
                ...coverage.liability,
                [action.payload.name]: {
                  ...coverage.liability[action.payload.name],
                  ...(action.payload.limit !== undefined && {
                    limit: action.payload.limit,
                  }),
                  ...(action.payload.premium !== undefined && {
                    premium: action.payload.premium,
                  }),
                },
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    changeInsuranceCovergaeAccidentBenefits: (
      state,
      action: PayloadAction<{ index: number; value: number }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              accident_benefits: {
                ...coverage.accident_benefits,
                premium: action.payload.value,
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    changeInsuranceCoverageUninsuredAutomobile: (
      state,
      action: PayloadAction<{ index: number; value: number }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              uninsured_automobile: {
                ...coverage.uninsured_automobile,
                premium: action.payload.value,
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    changeInsuranceCoverageDirectCompensation: (
      state,
      action: PayloadAction<{
        index: number;
        deductible?: number;
        premium?: number;
      }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              direct_compensation: {
                ...coverage.direct_compensation,
                ...(action.payload.deductible !== undefined && {
                  deductible: action.payload.deductible,
                }),
                ...(action.payload.premium !== undefined && {
                  premium: action.payload.premium,
                }),
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    changeInsuranceCoverageLossOrDamage: (
      state,
      action: PayloadAction<{
        index: number;
        name: string;
        deductible: number;
        premium: number;
      }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              loss_or_damage: {
                ...coverage.loss_or_damage,
                [action.payload.name]: {
                  ...coverage.loss_or_damage[action.payload.name],
                  ...(action.payload.deductible !== undefined && {
                    deductible: action.payload.deductible,
                  }),
                  ...(action.payload.premium !== undefined && {
                    premium: action.payload.premium,
                  }),
                },
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    changePolicyChangeFormsFamilyProtectionCoverage: (
      state,
      action: PayloadAction<{ index: number; value: number }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              policy_change_forms: {
                ...coverage.policy_change_forms,
                family_protection_coverage: {
                  ...coverage.policy_change_forms.family_protection_coverage,
                  premium: action.payload.value,
                },
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    changePolicyChangeFormsOtherCoverages: (
      state,
      action: PayloadAction<{
        index: number;
        subIndex: number;
        name: string;
        value: string;
      }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              policy_change_forms: {
                ...coverage.policy_change_forms,
                other_coverages:
                  coverage.policy_change_forms.other_coverages.map(
                    (otherCoverage: any, otherCoverageIndex: number) => {
                      if (action.payload.subIndex === otherCoverageIndex) {
                        return {
                          ...otherCoverage,
                          [action.payload.name]: action.payload.value,
                        };
                      }

                      return otherCoverage;
                    },
                  ),
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    addPolicyChangeFormsOtherCoverages: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload) {
            return {
              ...coverage,
              policy_change_forms: {
                ...coverage.policy_change_forms,
                other_coverages: [
                  ...coverage.policy_change_forms.other_coverages,
                  {
                    name: '',
                    deductible_limit: '',
                    premium: '',
                  },
                ],
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    removePolicyChangeFormsOtherCoverages: (
      state,
      action: PayloadAction<{ index: number; subIndex: number }>,
    ) => {
      state.data.insurance_coverages = state.data.insurance_coverages.map(
        (coverage: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...coverage,
              policy_change_forms: {
                ...coverage.policy_change_forms,
                other_coverages:
                  coverage.policy_change_forms.other_coverages.filter(
                    (_: any, subIndex: number) =>
                      subIndex !== action.payload.subIndex,
                  ),
              },
            };
          }

          return coverage;
        },
      );

      updateLocalStore(state);
    },

    changeMethodOfPayment: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.method_of_payment = {
        ...state.data.method_of_payment,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeDeclarationOfApplicant: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.declaration_of_applicant = {
        ...state.data.declaration_of_applicant,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeReportOfBrokerAgent: (
      state,
      action: PayloadAction<{ name: string; value: string }>,
    ) => {
      state.data.report_of_broker_agent = {
        ...state.data.report_of_broker_agent,
        [action.payload.name]: action.payload.value,
      };

      updateLocalStore(state);
    },

    changeOtherLoss: (
      state,
      action: PayloadAction<{
        name: string;
        index: number;
        value: string;
      }>,
    ) => {
      state.data.other_losses = state.data.other_losses.map(
        (claim: any, index: number) => {
          if (index === action.payload.index) {
            return {
              ...claim,
              [action.payload.name]: action.payload.value,
            };
          }

          return claim;
        },
      );

      updateLocalStore(state);
    },

    addOtherLoss: (state) => {
      state.data.other_losses = [
        ...state.data.other_losses,
        {
          auto_nr: '',
          date: '',
          details: '',
          driver_nr: '',
          is_ab: false,
          is_bi: false,
          is_coll_ap: false,
          is_comp_sp: false,
          is_dcpd: false,
          is_pd: false,
          is_ua: false,
          paid: '',
        },
      ];

      updateLocalStore(state);
    },

    removeOtherLoss: (state, action: PayloadAction<number>) => {
      state.data.other_losses = state.data.other_losses.filter(
        (_: any, index: number) => index !== action.payload,
      );

      updateLocalStore(state);
    },

    changeLiabilitySlipDays: (state, action: PayloadAction<string>) => {
      state.liability_slip = {
        ...state.liability_slip,
        days: action.payload,
      };

      updateLocalStore(state);
    },

    changeLiabilitySlip: (state, action: PayloadAction<APILiabilitySlip>) => {
      state.liability_slip = action.payload;

      updateLocalStore(state);
    },

    addMissingMandatoryField: (state, action: PayloadAction<string>) => {
      state.missingMandatoryFields.push(action.payload);
    },

    removeMissingMandatoryField: (state, action: PayloadAction<string>) => {
      state.missingMandatoryFields = state.missingMandatoryFields.filter(
        (field) => field !== action.payload,
      );
    },

    addMissingRemarksField: (
      state,
      action: PayloadAction<{ validationId: string; key: string }>,
    ) => {
      if (!(action.payload.validationId in state.missingRemarksFields)) {
        state.missingRemarksFields[action.payload.validationId] = [];
      }

      if (
        !state.missingRemarksFields[action.payload.validationId].includes(
          action.payload.key,
        )
      ) {
        state.missingRemarksFields[action.payload.validationId].push(
          action.payload.key,
        );
      }
    },

    removeMissingRemarksField: (
      state,
      action: PayloadAction<{ validationId: string; key: string }>,
    ) => {
      if (!(action.payload.validationId in state.missingRemarksFields)) return;

      state.missingRemarksFields[action.payload.validationId] =
        state.missingRemarksFields[action.payload.validationId].filter(
          (field) => field !== action.payload.key,
        );

      if (
        state.missingRemarksFields[action.payload.validationId].length === 0
      ) {
        delete state.missingRemarksFields[action.payload.validationId];
      }
    },

    changeSubmissionStatus: (
      state,
      action: PayloadAction<APISubmissionStatuses>,
    ) => {
      state.status = action.payload;
    },
  },
});

export const {
  storeData,
  resetData,
  changePolicyInformation,
  changeLanguage,
  changeCompanyAndBrokerInformation,
  changeLessor,
  changeApplicant,
  changePolicyPeriod,
  changeRemarks,
  changeFiles,
  removeFiles,
  changeVehicle,
  changeVehicleSummary,
  changeUnlistedVehicles,
  addUnlistedVehicle,
  changeDriver,
  changeDriverVehiclePercentageUse,
  changeDriverSummary,
  changeMostRecentPolicy,
  addUnlistedSpouse,
  changeUnlistedSpouse,
  changeAccidentsAndClaims,
  addAccidentsAndClaims,
  removeAccidentsAndClaims,
  changeConvictions,
  addConviction,
  removeConviction,
  changeRatingInformationDrivingRecord,
  addRatingInformationDrivingRecord,
  removeRatingInformationDrivingRecord,
  changeRatingInformationDiscounts,
  addRatingInformationDiscounts,
  removeRatingInformationDiscount,
  changeRatingInformationDiscountsDiscount,
  addRatingInformationDiscountsDiscount,
  removeRatingInformationDiscountsDiscount,
  addUnlistedDriver,
  changeUnlistedDriver,
  changeInsuranceCoverageLiability,
  changeInsuranceCovergaeAccidentBenefits,
  changeInsuranceCoverageUninsuredAutomobile,
  changeInsuranceCoverageDirectCompensation,
  changeInsuranceCoverageLossOrDamage,
  changePolicyChangeFormsFamilyProtectionCoverage,
  changePolicyChangeFormsOtherCoverages,
  addPolicyChangeFormsOtherCoverages,
  removePolicyChangeFormsOtherCoverages,
  changeMethodOfPayment,
  changeDeclarationOfApplicant,
  changeReportOfBrokerAgent,
  changeIsDataSaved,
  changeOtherLoss,
  addOtherLoss,
  removeOtherLoss,
  changeLiabilitySlipDays,
  changeLiabilitySlip,
  addMissingMandatoryField,
  removeMissingMandatoryField,
  addMissingRemarksField,
  removeMissingRemarksField,
  changeSubmissionStatus,
} = submissionFormSlice.actions;

export default submissionFormSlice.reducer;
