import { useContext } from 'react';

import { useAppSelector } from 'hooks';

import TextField from 'components/fields/TextField';

import { AuthContext } from 'contexts/AuthContext';

import isSubmissionDisabled from 'views/submission/helpers/isSubmissionDisabled';

const TextFieldInput = ({ ...rest }) => {
  const { organization } = useContext(AuthContext);

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const isDisabled = isSubmissionDisabled(submissionStatus, organization.role);

  return (
    <TextField
      {...rest}
      isDisabled={isDisabled}
      backgroundColor={isDisabled ? 'gray.300' : '#fff'}
    />
  );
};

export default TextFieldInput;
