import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, SimpleGrid, Box, Flex } from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';
import CheckBoxInput from '../../components/CheckBoxInput';
import DateInput from '../../components/DateInput';
import SelectInput from '../../components/SelectInput';

import getDateFromString from '../../helpers/getDateFromString';
import getDateString from '../../helpers/getDateString';

import { changeReportOfBrokerAgent } from 'reducers/submissionFormReducer';

const CARD_TYPES_OPTIONS = [
  { value: 'Temp', label: 'Temp' },
  { value: 'Perm', label: 'Perm' },
  { value: 'None', label: 'None' },
];

const ReportOfBrokerAgent = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.submissionForm.data.report_of_broker_agent,
  );

  const onchangeReportOfBrokerAgentChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    const payload = type === 'checkbox' ? checked : value;

    dispatch(changeReportOfBrokerAgent({ name, value: payload }));
  };

  const onDateChange = (date: Date, name: string) => {
    dispatch(changeReportOfBrokerAgent({ name, value: getDateString(date) }));
  };

  return (
    <Card mb="20px" id="sectionReportOfBrokerAgent">
      <Text fontWeight="bold" fontSize="2xl">
        Report of Broker/Agent
      </Text>

      <Flex gap="20px" alignItems={'center'}>
        <Box w="25%">
          <CheckBoxInput
            name="is_this_risk_bound"
            isChecked={data.is_this_risk_bound}
            onChange={onchangeReportOfBrokerAgentChange}
          >
            Have you bound this risk?
          </CheckBoxInput>
        </Box>

        <Box w="25%">
          <CheckBoxInput
            name="is_this_business_new_to_you"
            isChecked={data.is_this_business_new_to_you}
            onChange={onchangeReportOfBrokerAgentChange}
          >
            Is this business new to you?
          </CheckBoxInput>
        </Box>

        <Box w="25%">
          <CheckBoxInput
            name="is_inspection_completed"
            isChecked={data.is_inspection_completed}
            onChange={onchangeReportOfBrokerAgentChange}
          >
            Has an inspection been completed?
          </CheckBoxInput>
        </Box>

        <Box w="25%">
          <SelectInput
            label="Type of Motor Vehicle Liability Insurance Card issued"
            name="type_card_issued"
            value={data.type_card_issued}
            options={CARD_TYPES_OPTIONS}
            onChange={onchangeReportOfBrokerAgentChange}
          />
        </Box>
      </Flex>

      <SimpleGrid columns={4} spacing="20px" mt="20px">
        <FormInput
          label="How long have you known the Applicant?"
          name="how_long_have_you_known_the_applicant"
          value={data.how_long_have_you_known_the_applicant}
          onChange={onchangeReportOfBrokerAgentChange}
        />

        <FormInput
          label="How long have you known the Principal Driver? "
          name="how_long_have_you_known_the_principal_driver"
          value={data.how_long_have_you_known_the_principal_driver}
          onChange={onchangeReportOfBrokerAgentChange}
        />

        <FormInput
          label="Broker/Agent Signature"
          name="broker_agent_signature"
          value={data.broker_agent_signature}
          onChange={onchangeReportOfBrokerAgentChange}
        />

        <DateInput
          label="Instalment Due Date"
          date={getDateFromString(data.date)}
          onChange={(date: Date) => onDateChange(date, 'date')}
        />
      </SimpleGrid>
    </Card>
  );
};

export default ReportOfBrokerAgent;
