import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import Storage from 'storage';

import { apiApplicationFormData, apiValidations } from 'api';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import PolicyInformation from './sections/PolicyInformation';
import InsuranceCompanyInformation from './sections/InsuranceCompanyInformation';
import Lessor from './sections/Lessor';
import Applicant from './sections/Applicant';
import PolicyPeriod from './sections/PolicyPeriod';
import Vehicles from './sections/Vehicles';
import Drivers from './sections/Drivers';
import Failures from './sections/Failures';
import Claims from './sections/Claims';
import Convictions from './sections/Convictions';
import RatingInformation from './sections/RatingInformation';
import Remarks from './sections/Remarks';
import Submit from './sections/Submit';
import InsuranceCoverages from './sections/InsuranceCoverages';
import MethodOfPayment from './sections/MethodOfPayment';
import DeclarationOfApplicant from './sections/DeclarationOfApplicant';
import ReportOfBrokerAgent from './sections/ReportOfBrokerAgent';
import PersonalConsent from './sections/PersonalConsent';
import LiabilitySlip from './sections/LiabilitySlip';
import MandatoryFields from './sections/MandatoryFields';

import SectionMenu from './components/SectionMenu';

import { useAppSelector, useAppDispatch } from 'hooks';

import { storeData, resetData } from 'reducers/submissionFormReducer';

import type { APIApplicationFormData } from 'types/api.types';

const SubmissionView = () => {
  const { uuid } = useParams();
  const dispatch = useAppDispatch();
  const isDataSet = useAppSelector((state) => state.submissionForm.isDataSet);

  const {
    error,
    data: initialData,
    isFetching,
  } = useQuery({
    queryKey: ['app_form', uuid],
    queryFn: () => apiApplicationFormData(uuid),
    refetchOnWindowFocus: false,
  }) as {
    error: any;
    data: APIApplicationFormData;
    isFetching: boolean;
  };

  const { data: validations } = useQuery({
    queryKey: [`validations_${uuid}`],
    queryFn: () => apiValidations(uuid),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(resetData());
  }, []);

  useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (!key || !key.startsWith('policy_pilot_app_form_')) continue;

      Storage.removeByKey(key);
    }
  }, [uuid]);

  useEffect(() => {
    if (isDataSet || isFetching || !initialData?.data) return;

    dispatch(
      storeData({
        uuid,
        data: initialData.data,
        remarks: initialData.remarks,
        files: initialData.files,
        liability_slip: initialData.liability_slip,
        status: initialData.status,
      }),
    );
  }, [initialData, uuid, isDataSet, isFetching, dispatch]);

  if (!isDataSet) {
    return <Card textAlign="center">Preparing form...</Card>;
  }

  if (!validations) {
    return <Card textAlign="center">Loading validations...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <ApplicationFormContext.Provider
      value={{ validations: validations?.validations || [] }}
    >
      <SectionMenu />
      <Box mt="70px" />
      <Failures />
      <PolicyInformation />
      <InsuranceCompanyInformation />
      <Applicant />
      <Lessor />
      <PolicyPeriod />
      <Vehicles />
      <Drivers />
      <Claims />
      <Convictions />
      <RatingInformation />
      <InsuranceCoverages />
      <MethodOfPayment />
      <DeclarationOfApplicant />
      <ReportOfBrokerAgent />
      <Remarks />
      <PersonalConsent />
      <MandatoryFields />
      <Flex gap="20px">
        <Submit />
        <LiabilitySlip />
      </Flex>
    </ApplicationFormContext.Provider>
  );
};

export default SubmissionView;
