import { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Paginate } from 'react-paginate-chakra-ui';

import Card from 'components/card/Card';

import {
  Flex,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Link,
} from '@chakra-ui/react';

import { apiUsers } from 'api';

import config from 'config';

import type { APIUserListData } from 'types/api.types';

import { AuthContext } from 'contexts/AuthContext';

const TABLE_HEADERS = [
  'First Name',
  'Last Name',
  'Email',
  'Date joined',
  'Role',
  'Actions',
];

const UserListView = () => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const { error, data, isLoading } = useQuery({
    queryKey: ['user-list', page],
    queryFn: () => apiUsers(page + 1),
  }) as {
    data: APIUserListData;
    isLoading: boolean;
    error: any;
  };

  if (isLoading) {
    return <Card textAlign="center">Loading users...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      mb="20px"
    >
      <Flex px="20px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Organisation users
        </Text>
      </Flex>

      <Table variant="simple" color="gray.500" mt="20px">
        <Thead>
          <Tr>
            {TABLE_HEADERS.map((header) => (
              <Th key={header} pe="10px" borderColor={borderColor}>
                <Flex
                  justifyContent="space-between"
                  align="center"
                  fontSize={{ sm: '10px', lg: '12px' }}
                  color="gray.400"
                >
                  {header}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.results.map((member, index) => (
            <Tr key={index}>
              <Td>{member.user.first_name}</Td>
              <Td>{member.user.last_name}</Td>
              <Td>{member.user.email}</Td>
              <Td>{member.user.date_joined}</Td>
              <Td>{member.role}</Td>
              <Td>
                {member.user.email === user.email && '-'}
                {member.user.email !== user.email && (
                  <Link
                    color="teal.500"
                    onClick={() =>
                      navigate(`/admin/users/manage/${member.user.id}`)
                    }
                  >
                    Edit
                  </Link>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {config.ITEMS_PER_PAGE < data.count && (
        <Paginate
          page={page}
          count={data.count}
          pageSize={config.ITEMS_PER_PAGE}
          onPageChange={(page: number) => setPage(page)}
        />
      )}
    </Card>
  );
};

export default UserListView;
