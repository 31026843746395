const ROLE_ADMIN = 1;
const ROLE_UNDERWRITER = 2;
const ROLE_BROKER = 3;

const SUBMISSION_STATUS_FAILED = 1;
const SUBMISSION_STATUS_IN_PROGRESS = 2;
const SUBMISSION_STATUS_IN_REVIEW = 3;
const SUBMISSION_STATUS_APPROVED = 4;
const SUBMISSION_STATUS_COMPLETED = 5;

const ROLES_OPTIONS = [
  { value: `${ROLE_BROKER}`, label: 'Broker' },
  { value: `${ROLE_UNDERWRITER}`, label: 'Underwriter' },
  { value: `${ROLE_ADMIN}`, label: 'Admin' },
];

export {
  ROLE_ADMIN,
  ROLE_UNDERWRITER,
  ROLE_BROKER,
  ROLES_OPTIONS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_IN_PROGRESS,
  SUBMISSION_STATUS_IN_REVIEW,
  SUBMISSION_STATUS_APPROVED,
  SUBMISSION_STATUS_COMPLETED,
};
