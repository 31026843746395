import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import Card from 'components/card/Card';

import {
  Flex,
  Text,
  useColorModeValue,
  SimpleGrid,
  Button,
  Box,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';

import { apiUser, apiUpdateUser, apiRemoveUser } from 'api';

import { ROLES_OPTIONS } from 'variables/constants';

const sanitizeFieldName = (name: string) => {
  let newName = name.replace(/_/g, ' ');
  return newName.charAt(0).toUpperCase() + newName.slice(1);
};

const EditUserView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: '',
    first_name: '',
    last_name: '',
    role: '',
  });
  const [password, setPassword] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { error, data, isLoading } = useQuery({
    queryKey: ['user', id],
    queryFn: () => apiUser(id),
  });

  const updateMutation = useMutation({
    mutationFn: (data) => apiUpdateUser(id, data),
    onSuccess: () => {
      setPassword('');
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => apiRemoveUser(id),
    onSuccess: () => {
      navigate('/admin/users/manage');
    },
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const onInputChange = (e: any) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    if (data) {
      setUser({
        email: data.user.email,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        role: data.role_id,
      });
    }
  }, [data]);

  if (isLoading) {
    return <Card textAlign="center">Loading user data...</Card>;
  }

  if (error) {
    return (
      <Card textAlign="center">
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{JSON.stringify(error)}</AlertDescription>
        </Alert>
      </Card>
    );
  }

  return (
    <>
      <Card mb="20px">
        <Flex fontWeight="bold" fontSize="2xl" mb="20px">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Update user
          </Text>
        </Flex>

        <SimpleGrid columns={2} spacing="20px">
          <SimpleGrid columns={1} spacing="20px">
            <InputField
              name="email"
              label="Email"
              placeholder="example@example.com"
              value={user.email}
              onChange={onInputChange}
            />
            <InputField
              name="first_name"
              label="First name"
              value={user.first_name}
              onChange={onInputChange}
            />
            <InputField
              name="last_name"
              label="Last name"
              value={user.last_name}
              onChange={onInputChange}
            />
            <SelectField
              name="role"
              label="Role"
              value={user.role}
              options={ROLES_OPTIONS}
              onChange={onInputChange}
            />
            <Button
              onClick={() => updateMutation.mutate(user as any)}
              w="30%"
              variant="brand"
              disabled={updateMutation.isPending}
            >
              {updateMutation.isPending ? <Spinner /> : 'Update user'}
            </Button>

            <Flex fontWeight="bold" fontSize="2xl" mt="20px">
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
              >
                Change password
              </Text>
            </Flex>

            <InputField
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              onClick={() =>
                updateMutation.mutate({ ...user, password } as any)
              }
              w="30%"
              variant="brand"
              disabled={updateMutation.isPending}
            >
              {updateMutation.isPending ? <Spinner /> : 'Change password'}
            </Button>
          </SimpleGrid>

          <Box mt="30px">
            <Stack spacing={3}>
              {updateMutation.isError && (
                <>
                  {Object.entries(
                    updateMutation.error as unknown as {
                      [key: string]: string[];
                    },
                  ).map(([key, messages]) => (
                    <div key={key}>
                      {messages.map((msg) => (
                        <Alert status="warning" key={msg}>
                          <AlertIcon />
                          <Box>
                            <AlertTitle>{sanitizeFieldName(key)}</AlertTitle>
                            <AlertDescription>{msg}</AlertDescription>
                          </Box>
                        </Alert>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </Stack>

            {updateMutation.isSuccess && (
              <Alert status="success">
                <AlertIcon />
                <Box>
                  <AlertTitle>Success!</AlertTitle>
                  <AlertDescription>
                    User has been successfully updated
                  </AlertDescription>
                </Box>
              </Alert>
            )}
          </Box>
        </SimpleGrid>
      </Card>

      <Card mb="20px">
        <Flex fontWeight="bold" fontSize="2xl" mb="20px">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Danger zone
          </Text>
        </Flex>

        <SimpleGrid columns={2} spacing="20px">
          <Button
            color="red"
            variant="outline"
            w="50%"
            onClick={() => setIsOpen(true)}
          >
            Remove user from organisation
          </Button>
        </SimpleGrid>
      </Card>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove user from organisation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button
              color="red"
              variant="outline"
              w="100%"
              onClick={() => {
                deleteMutation.mutate();
              }}
            >
              Confirm
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditUserView;
