import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, Grid, GridItem } from '@chakra-ui/react';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';
import DateInput from '../../components/DateInput';

import getDateFromString from '../../helpers/getDateFromString';
import getDateString from '../../helpers/getDateString';

import { changeDeclarationOfApplicant } from 'reducers/submissionFormReducer';

const DeclarationOfApplicant = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(
    (state) => state.submissionForm.data.declaration_of_applicant,
  );

  const onDeclarationOfApplicantChange = (event: any) => {
    const { name, value } = event.target;

    dispatch(changeDeclarationOfApplicant({ name, value }));
  };

  const onDateChange = (date: Date, name: string) => {
    dispatch(
      changeDeclarationOfApplicant({ name, value: getDateString(date) }),
    );
  };

  return (
    <Card mb="20px" id="sectionDeclarationOfApplicant">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Declaration of Applicant
      </Text>

      <Grid templateColumns="repeat(5, 1fr)" gap="20px">
        <GridItem colSpan={3}>
          <FormInput
            label="Applicant's Signature"
            name="signature"
            value={data.signature}
            onChange={onDeclarationOfApplicantChange}
          />
        </GridItem>

        <GridItem colSpan={2}>
          <DateInput
            label="Date"
            date={getDateFromString(data.date)}
            onChange={(date: Date) => onDateChange(date, 'date')}
          />
        </GridItem>
      </Grid>
    </Card>
  );
};

export default DeclarationOfApplicant;
