import { useContext, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Link,
  Grid,
  GridItem,
  Tooltip,
} from '@chakra-ui/react';

import Card from 'components/card/Card';

import { apiCreateApplicationLog, apiUpdateSubmission } from 'api';

import { AuthContext } from 'contexts/AuthContext';
import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import { setTokenCookie } from 'utils/cookies';

import {
  changeIsDataSaved,
  changeSubmissionStatus,
} from 'reducers/submissionFormReducer';

import {
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_IN_PROGRESS,
  SUBMISSION_STATUS_APPROVED,
  SUBMISSION_STATUS_IN_REVIEW,
  SUBMISSION_STATUS_COMPLETED,
  ROLE_BROKER,
  ROLE_UNDERWRITER,
  ROLE_ADMIN,
} from 'variables/constants';

const Submit = () => {
  const { uuid } = useParams();
  const { organization, user } = useContext(AuthContext);
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };
  const dispatch = useAppDispatch();

  const is_additional_risk = useAppSelector(
    (state) => state.submissionForm.data.vehicles_summary.is_additional_risk,
  );
  const data = useAppSelector((state) => state.submissionForm.data);
  const remarks = useAppSelector((state) => state.submissionForm.remarks);
  const files = useAppSelector((state) => state.submissionForm.files);
  const isDataSaved = useAppSelector(
    (state) => state.submissionForm.isDataSaved,
  );
  const missingMandatoryFields = useAppSelector(
    (state) => state.submissionForm.missingMandatoryFields,
  );
  const missingRemarksFields = useAppSelector(
    (state) => state.submissionForm.missingRemarksFields,
  );
  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const hasFailedValidations = useMemo(() => {
    if (!validations) return false;

    return validations.some(
      (validation: any) => validation.validation_outcome === 'fail',
    );
  }, [validations]);

  const onGenerateApplicationLog = async (openPDF: boolean) => {
    let generatePDF =
      organization.role === ROLE_BROKER &&
      submissionStatus === SUBMISSION_STATUS_IN_PROGRESS
        ? false
        : openPDF;

    dispatch(changeIsDataSaved(true));

    const missing = {
      mandatory_fields: missingMandatoryFields,
      remarks: missingRemarksFields,
    };

    await apiCreateApplicationLog(
      uuid,
      data,
      remarks,
      files,
      missing,
      generatePDF,
    ).then((response: any) => {
      if (generatePDF) {
        setTokenCookie(organization.token, user.token);
        window.open(response.url, '_blank');
      }
    });

    if (
      openPDF &&
      organization.role === ROLE_BROKER &&
      submissionStatus === SUBMISSION_STATUS_IN_PROGRESS
    ) {
      dispatch(changeSubmissionStatus(SUBMISSION_STATUS_IN_REVIEW));
      apiUpdateSubmission(uuid, { status: SUBMISSION_STATUS_IN_REVIEW });
    }
  };

  const shouldDisableSubmit = () => {
    if (organization.role === ROLE_ADMIN) return false;

    if (submissionStatus === SUBMISSION_STATUS_FAILED) return true;

    if (organization.role === ROLE_BROKER) {
      if (submissionStatus === SUBMISSION_STATUS_IN_REVIEW) return true;

      return false;
    }

    if (is_additional_risk) return true;

    return false;
  };

  const shouldDisableSave = () => {
    if (organization.role === ROLE_ADMIN) return isDataSaved;

    if (submissionStatus === SUBMISSION_STATUS_FAILED) return true;

    if (submissionStatus === SUBMISSION_STATUS_APPROVED) return true;

    if (submissionStatus === SUBMISSION_STATUS_COMPLETED) return true;

    return isDataSaved;
  };

  const getGenerateAppCTAText = () => {
    if (organization.role === ROLE_ADMIN) {
      if (
        [
          SUBMISSION_STATUS_FAILED,
          SUBMISSION_STATUS_COMPLETED,
          SUBMISSION_STATUS_APPROVED,
        ].includes(submissionStatus) ||
        is_additional_risk
      ) {
        return 'Get Application PDF (will generate a new PDF)';
      }

      if (
        [SUBMISSION_STATUS_IN_REVIEW, SUBMISSION_STATUS_IN_PROGRESS].includes(
          submissionStatus,
        )
      ) {
        return 'Preview Application PDF';
      }
    }

    if (submissionStatus === SUBMISSION_STATUS_FAILED) {
      return 'Resolve failed validations to print application';
    }

    if (is_additional_risk) {
      return 'Resolve additional risk';
    }

    if (
      [SUBMISSION_STATUS_APPROVED, SUBMISSION_STATUS_COMPLETED].includes(
        submissionStatus,
      )
    ) {
      return 'Get Application PDF';
    }

    if (organization.role === ROLE_BROKER) {
      if (submissionStatus === SUBMISSION_STATUS_IN_PROGRESS) {
        return 'Submit for underwriter review';
      }

      if (submissionStatus === SUBMISSION_STATUS_IN_REVIEW) {
        return 'Being reviewed by underwriter';
      }
    }

    if (organization.role === ROLE_UNDERWRITER) {
      if (submissionStatus === SUBMISSION_STATUS_IN_PROGRESS) {
        return 'Preview Application PDF';
      }

      if (submissionStatus === SUBMISSION_STATUS_IN_REVIEW) {
        return 'Preview Application PDF';
      }
    }

    return 'Unhandled';
  };

  const getTooltipText = () => {
    if (organization.role !== ROLE_ADMIN) return '';

    if (
      [SUBMISSION_STATUS_FAILED, SUBMISSION_STATUS_COMPLETED].includes(
        submissionStatus,
      )
    ) {
      return 'Admin override enabled';
    }

    return '';
  };

  return (
    <Box w="100%">
      <Card mb="20px">
        {hasFailedValidations && (
          <Alert status="info" mb="20px">
            <AlertIcon />
            <Box>
              <AlertTitle>Failed validations</AlertTitle>
              <AlertDescription>
                Printing application will not be available until all{' '}
                <Link color="teal.500" onClick={() => window.scrollTo(0, 0)}>
                  failed validations
                </Link>{' '}
                are resolved.
              </AlertDescription>
            </Box>
          </Alert>
        )}

        {!hasFailedValidations && is_additional_risk && (
          <Alert status="info" mb="20px">
            <AlertIcon />
            <Box>
              <AlertTitle>Carries additional risk</AlertTitle>
              <AlertDescription>
                Printing application will not be available if{' '}
                <strong>
                  "Will any of the described automobiles be rented or leased to
                  others, or used to carry passengers for compensation or hire,
                  or haul a trailer, or carry explosives or radioactive
                  material?"
                </strong>{' '}
                is selected
              </AlertDescription>
            </Box>
          </Alert>
        )}

        {!hasFailedValidations && missingMandatoryFields.length > 0 && (
          <Alert status="info" mb="20px">
            <AlertIcon />
            <Box>
              <AlertTitle>Missing Mandatory fields</AlertTitle>
              <AlertDescription>
                Printing application will not be available until all mandatory
                fields are filled.
              </AlertDescription>
            </Box>
          </Alert>
        )}

        {!hasFailedValidations &&
          Object.keys(missingRemarksFields).length > 0 && (
            <Alert status="info" mb="20px">
              <AlertIcon />
              <Box>
                <AlertTitle>Missing remarks and uploads</AlertTitle>
                <AlertDescription>
                  Printing application will not be available until all remarks
                  and files are collected.
                </AlertDescription>
              </Box>
            </Alert>
          )}

        <Grid templateColumns="repeat(6, 1fr)" gap="20px">
          <GridItem colSpan={1}>
            <Tooltip placement="top" label={getTooltipText()}>
              <Button
                onClick={() => onGenerateApplicationLog(false)}
                disabled={shouldDisableSave()}
                colorScheme={isDataSaved ? 'brand' : 'yellow'}
                w="100%"
              >
                Save changes
              </Button>
            </Tooltip>
          </GridItem>

          <GridItem colSpan={5}>
            <Tooltip placement="top" label={getTooltipText()}>
              <Button
                onClick={() => onGenerateApplicationLog(true)}
                disabled={shouldDisableSubmit()}
                _hover={{ bg: 'brand.500' }}
                variant="brand"
                w="100%"
              >
                {getGenerateAppCTAText()}
              </Button>
            </Tooltip>
          </GridItem>
        </Grid>
      </Card>
    </Box>
  );
};

export default Submit;
