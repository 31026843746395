import { useContext } from 'react';

import { useAppSelector } from 'hooks';

// @ts-ignore
import { HashLink } from 'react-router-hash-link';

import {
  ListItem,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  Flex,
} from '@chakra-ui/react';

import { ApplicationFormContext } from 'contexts/ApplicationFormContext';

import capitalizeFirstLetterInAllWords from '../../helpers/capitalizeFirstLetterInAllWords';

import { SUBMISSION_STATUS_FAILED } from 'variables/constants';

const MandatoryFields = () => {
  const { validations } = useContext(ApplicationFormContext) as {
    validations: Validation[];
  };

  const missingFields = useAppSelector(
    (state) => state.submissionForm.missingMandatoryFields,
  );

  const missingRemarksFields = useAppSelector(
    (state) => state.submissionForm.missingRemarksFields,
  );

  const submissionStatus = useAppSelector(
    (state) => state.submissionForm.status,
  );

  const scrollWithOffset = (el: any, yOffset: number) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const getValidationName = (id: string) => {
    const validation = validations.find((v) => v.validation_id === id);

    if (validation) return validation.validation_name;

    const name = id.replace(/_/g, ' ');

    return capitalizeFirstLetterInAllWords(name);
  };

  const getSubFieldName = (name: string) => {
    return capitalizeFirstLetterInAllWords(name.replace(/-/g, ' '));
  };

  if (submissionStatus === SUBMISSION_STATUS_FAILED) {
    return null;
  }

  return (
    <Flex gap="20px">
      {missingFields.length > 0 && (
        <Alert status="warning" mb="20px" alignItems="top">
          <AlertIcon />
          <Box>
            <AlertTitle>Missing Mandatory fields</AlertTitle>
            <AlertDescription>
              <UnorderedList ml="30px" mt="5px">
                {missingFields.map((field, index) => (
                  <ListItem key={index}>
                    <HashLink
                      smooth
                      to={`#${field}`}
                      scroll={(el: any) => scrollWithOffset(el, -184)}
                    >
                      {field}
                    </HashLink>
                  </ListItem>
                ))}
              </UnorderedList>
            </AlertDescription>
          </Box>
        </Alert>
      )}

      {Object.entries(missingRemarksFields).length > 0 && (
        <Alert status="warning" mb="20px" alignItems="top">
          <AlertIcon />
          <Box>
            <AlertTitle>Missing remarks and uploads</AlertTitle>
            <AlertDescription>
              <UnorderedList ml="30px" mt="5px">
                {Object.entries(missingRemarksFields).map((field, index) => (
                  <ListItem key={index}>
                    {field[1][0] === 'global' ? (
                      <HashLink
                        smooth
                        to={`#${field[0]}`}
                        scroll={(el: any) => scrollWithOffset(el, -200)}
                      >
                        {getValidationName(field[0])}
                      </HashLink>
                    ) : (
                      getValidationName(field[0])
                    )}

                    {field[1][0] !== 'global' && (
                      <UnorderedList ml="30px" mt="5px">
                        {field[1].map((subField: string) => (
                          <ListItem key={subField}>
                            <HashLink
                              smooth
                              to={`#${field[0]}-${subField}`}
                              scroll={(el: any) => scrollWithOffset(el, -225)}
                            >
                              {getSubFieldName(subField)}{' '}
                            </HashLink>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    )}
                  </ListItem>
                ))}
              </UnorderedList>
            </AlertDescription>
          </Box>
        </Alert>
      )}
    </Flex>
  );
};

export default MandatoryFields;
